<template>
  <div class="main-profile" v-loading="loading">
    <el-row :gutter="10" align="middle" justify="center">
      <el-col
        :xl="5"
        :lg="5"
        :md="24"
        :sm="24"
        :xs="24"
        style="text-align: center !important"
      >
        <div>
          <div>
            <el-button type="text" class="my-3">
              <el-tooltip
                :content="
                  getUserprofilepicture && getUserprofilepicture.length > 5
                    ? 'Update Profile Picture'
                    : 'Add Profile Picture'
                "
              >
                <img
                  v-if="
                    getUserprofilepicture && getUserprofilepicture.length > 5
                  "
                  :src="getUserprofilepicture"
                  alt="Avatar"
                  width="120"
                  height="120"
                  style="border-radius: 50%"
                  class="inline-block"
                  @click="outerVisible = true"
                />
                <img
                  v-else
                  src="@/assets/img/avatar.svg"
                  alt="Avatar"
                  width="120"
                  style="border-radius: 50%"
                  class="inline-block"
                  @click="outerVisible = true"
                />
              </el-tooltip>
              <span
                style="margin-top: 20px; margin-left: -45px"
                v-if="getUserprofilepicture && getUserprofilepicture.length > 5"
              >
                <!-- show delete icon -->
                <el-button
                  style="background-color: ivory"
                  type="plain"
                  size="mini"
                  circle
                  @click="deleteProfile()"
                >
                  <img width="12px" src="@/assets/Trash.svg" />
                </el-button>
              </span>
            </el-button>

            <!-- <div style="margin-top: -50px">
              <el-button
                style="background-color: whitesmoke; margin-left: 165px"
                type="plain"
                size="mini"
                circle
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </div>-->
          </div>
        </div>
      </el-col>

      <el-col
        style="margin-top: 2rem"
        class="grid-content"
        :xl="{
          span: 11,
          offset: 1,
        }"
        :lg="{
          span: 11,
          offset: 1,
        }"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <div>
          <h1>{{ getAuthenticatedUser | getUserFullName }}</h1>
          <div style="color: black">
            <el-row>
              <p v-if="getAuthenticatedUser && getAuthenticatedUser.title">
                {{ getAuthenticatedUser.title }}
              </p>
            </el-row>
            <el-row>
              <!-- <p>Joined on January 1, 2023</p> -->
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row
      style="margin-top: 2em"
      :span="14"
      :gutter="10"
      v-if="alltabs && alltabs.length"
    >
      <el-col :xl="4" :lg="4" :md="4" :sm="10" :xs="24">
        <div class="profile-tabs" style="color: #f754a2 color: #9299b8;">
          <el-tabs
            :tab-position="getIsMobile ? 'top' : 'left'"
            @tab-click="handleClick"
            v-model="tabModel"
          >
            <el-tab-pane
              v-for="tab in alltabs"
              :key="tab._id"
              :label="getTabLabel(tab)"
              :name="tab.templateInfo._id"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col
        :span="getIsMobile ? 24 : 5"
        :xl="20"
        :lg="20"
        :md="20"
        :sm="20"
        :xs="24"
      >
        <el-card class="box-card">
          <div slot="header" v-if="activeTab && activeTab.templateInfo">
            <span style="font-weight: 500; font-size: 16px">
              {{ getTabLabel(activeTab) }}
            </span>
            <div class="float-right d-flex">
              <div class="actions-sty mr-1">
                <el-button-group v-if="activeTab && activeTab.is_repeatable">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Table view"
                    placement="top-start"
                  >
                    <el-button
                      plain
                      size="mini"
                      :style="getSelectedStyle1"
                      @click="toggleBgColor"
                    >
                      <img
                        v-if="isKanban"
                        src="@/assets/img/Table.svg"
                        alt="icon"
                        class="img-sty"
                      />
                      <img
                        v-if="!isKanban"
                        src="@/assets/img/TableActive.svg"
                        alt="icon"
                        class="img-sty"
                      />
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Kanban view"
                    placement="top-start"
                  >
                    <el-button
                      plain
                      size="mini"
                      :style="getSelectedStyle"
                      @click="toggleBgColor"
                    >
                      <img
                        v-if="!isKanban"
                        src="@/assets/img/Kanban.svg"
                        alt="icon"
                        class="img-sty"
                      />
                      <img
                        v-if="isKanban"
                        src="@/assets/img/KanbanActive.svg"
                        alt="icon"
                        class="img-sty"
                      />
                    </el-button>
                  </el-tooltip>
                </el-button-group>
              </div>
              <el-button
                type="text"
                icon="el-icon-circle-plus-outline"
                style="margin-bottom: 17px"
                v-if="isEdit && activeTab.is_repeatable"
                @click="addRepeatableData()"
              >
                Add Data</el-button
              >
              <el-link
                style="float: right"
                v-if="hasPermission('EDIT') && !isEdit"
                @click="gotoEditMode"
                :underline="false"
              >
                <img src="@/assets/img/Edit.svg" alt="icon" />
              </el-link>
              <el-button
                icon="el-icon-refresh"
                style="float: right; margin-bottom: 17px"
                v-if="isEdit"
                @click="saveTemplateData"
                type="text"
                >Update</el-button
              >
            </div>
          </div>
          <el-scrollbar
            style="max-height: 350px; overflow-y: auto; flex-direction: column"
            native
            @scroll="handleScroll"
            v-if="!refresh"
          >
            <RepeatableTemplateView
              v-if="activeTab && activeTab.is_repeatable"
              :fieldsData="getCurrentTemplateFields"
              :form="form"
              :isEdit="isEdit"
              :isKanban="isKanban"
              :max_value="max_value"
              :min_value="min_value"
              :templateRules="getFormrules"
              :stepId="activeTab.template_id"
              :isStandard="checkIsStandard"
            ></RepeatableTemplateView>
            <PreviewTemplateFields
              v-else
              :fieldsData="getCurrentTemplateFields"
              :form="form"
              :isEdit="isEdit"
              :isStandard="checkIsStandard"
              :templateRules="getFormrules"
              :entityDataId="entityDataId"
              :entityId="getEntityDataById"
              :templateId="activeTab"
            ></PreviewTemplateFields>
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-empty description="No data configured"></el-empty>
    </el-row>
    <dialog-component
      :width="getIsMobile ? '100%' : (uploadOption === 'upload' ? '40%' : '70%')"
      :height="getIsMobile ? '100%' : '50%'"
      :containerWidth="getIsMobile ? '100%' : (uploadOption === 'upload' ? '40%' : '70%')"
      title="Update Profile Picture"
      :visible="outerVisible"
      @before-close="handleDialogClose"
    >
      <div class="radio-group-wrapper">
        <el-radio-group v-model="uploadOption" size="mini" @change="handleUploadOptionChange">
          <el-radio-button :label="'upload'">Upload from device</el-radio-button>
          <el-radio-button :label="'capture'">Capture from device</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="uploadOption === 'upload'">
      <vue-anka-cropper
        class="upload-file"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'circle',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: false,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          resultMimeType: 'image/png',
          resultMimeType: 'image/jpg',
          resultMimeType: 'image/gif',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-preview="uploadFile"
        @cropper-file-selected="uploadFile"
        @cropper-saved="onUpdate"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
       </div>

      <div v-else>
        <el-row>
            <el-col :span="12">
              <div>
                  <el-button @click="initializeCamera()" type="primary" size="mini" icon="el-icon-camera" v-if="!cameraStream">Click here to start</el-button>
                  <video ref="video" autoplay style="width: 100%;"></video>
              </div>
            </el-col>
            <el-col :span="10" :offset="1">
              <div class="preview-text">Preview
              </div>
              <div style="width: 400px; height: 300px; border: red dotted;">
                  <canvas ref="canvas" style="width: 400px; height: 300px;"></canvas>
              </div>
              <el-button @click="updateCapturedImage()" type="primary" size="mini" class="retake-btn" :disabled="!capturedImage">
                <i class="fa fa-cloud-upload"></i>Upload</el-button>
              <el-button @click="reCapturePic()" size="mini" class="retake-btn"><i class="fa fa-camera-retro"></i>Retake</el-button>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="5">
            <el-button @click="takePicture()" size="mini" type="primary" v-if="cameraStream">
              <i class="el-icon-camera"></i> Capture
            </el-button>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            files = null;
            handleDialogClose();
          "
        >Cancel</el-button>
        <el-button type="primary" @click="onUpdate()" v-if="uploadOption === 'upload'">Save</el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import { bus } from "../../main";
export default {
  mixins: [MobileRelatedHelper, FormbuilderHelper, userPermissionsHelper],
  components: {
    PreviewTemplateFields: () => import("./PreviewTemplateFields.vue"),
    RepeatableTemplateView: () => import("./RepeatableTemplateView.vue"),
    vueAnkaCropper,
  },
  data() {
    return {
      layout: "div",
      outerVisible: false,
      imgData: "",
      previewImage: "",
      profileData: null,
      upLoadEffect: false,
      buttondisale: true,
      tabPosition: "left",
      scrollTop: 0,
      loading: false,
      alltabs: [],
      permissionSet: {},
      activeTab: null,
      tabModel: "",
      entityPrimaryData: {},
      form: {},
      isEdit: false,
      minValue: 0,
      entitiesData: [],
      requiredFields: [],
      hasRepeatableTemplate: false,
      disabledFields: [],
      hideFields: [],
      bgColor: "#FFFFFF",
      bgColor2: "#FFFFFF",
      isKanban: false,
      max_value: 1000,
      min_value: 0,
      entityRepeatableTemplateData: {},
      entityDataId: null,
      files: null,
      refresh: false,
      entityId: null,
      profilePicture: null,
      uploadOption: 'upload',
      fileData: null,
      capturedImage: null,
      cameraStream: null,
    };
  },
 async  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (
      this.getActiveContactType?.contact_type?._id &&
      this.getActiveContactType?.account_data_id
    ) {
      this.entityId = this.getActiveContactType.contact_type._id;
      // await this.$store.dispatch("entities/fetchEntityById", {
      //   entity_id: this.entityId,
      // });
      this.getNeccesaryInfo(
        this.getActiveContactType?.contact_type?._id,
        this.getActiveContactType?.account_data_id
      );
    }
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveContactType",
      "getUpdateProfilePicStatus",
      "getupdateprofilepicture",
    ]),
    ...mapGetters("entities", ["getEntityDataById", "getEntityPrimaryData"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getPrimaryEntityDataStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
      "getRepeatableTemplateIds",
      "getFormbuilderTemplatesDataUpdateStatus",
      "getFormbuilderTemplatesDataUpdateData",
      "getRequiredTemplatesData",
      "getUploadAndUpdateDocument",
      "getformtemplateinvoiceinfo",
    ]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus", "getBase64FileUpload"]),
    getSelectedStyle() {
      if (this.isKanban) {
        return {
          background: "#409EFF",
        };
      }
      return "";
    },
    getSelectedStyle1() {
      if (!this.isKanban) {
        return {
          background: "#409EFF",
        };
      }
      return "";
    },
    getFormrules() {
      if (this.activeTab && this.activeTab?.templateInfo?.rules) {
        return this.activeTab?.templateInfo?.rules;
      }

      return [];
    },
    getCurrentTemplateFields() {
      if (this.activeTab && this.activeTab?.templateInfo?.sections) {
        return this.activeTab?.templateInfo?.sections[0].fields;
      }
      return [];
    },
    getUserprofilepicture() {
      return this.getAuthenticatedUser && this.getAuthenticatedUser.avatar
    },
    templateDataId() {
      if (
        this.activeTab?.template_id &&
        this.getEntityPrimaryData?.templates_data
      ) {
        let templateDataId = (
          this.getEntityPrimaryData.templates_data || []
        ).find((e) => e.template_id == this.activeTab.template_id);
        if (templateDataId?.template_data_id) {
          if (templateDataId?.template_data_id?._id) {
            return templateDataId?.template_data_id?._id;
          }
          return templateDataId?.template_data_id;
        }
      }
      return null;
    },
    checkIsStandard() {
      if (
        this.activeTab &&
        this.activeTab?.templateInfo?.type == "STANDARD" &&
        this.activeTab?.templateInfo?.slug == "contactdetails"
      ) {
        return true;
      }
      return false;
    },
  },
  created() {
    bus.$on("sendApRepeatableData", (data) => {
      if (data?.id == this.activeTab?.template_id) {
        this.entitiesData = data.data;
      }
    });

    bus.$on("pay-button-action-app-user", (data) => {
      console.log(data)
      // this.saveTemplateData();
      this.updateTemplateData();
      // if (data?.id == this.activeTab?.template_id) {
      //   this.entitiesData = data.data;
      // }
    });

    
  },
  methods: {
    handleDialogClose(){
      this.turnOffCamera();
      this.files = null;
      this.capturedImage = null;
      this.uploadOption = 'upload';
      this.outerVisible = false;
    },
    handleUploadOptionChange(newOption) {
      if (newOption === 'upload') {
        this.turnOffCamera();
      }
      this.files = null;
    },
    turnOffCamera(){
      if (this.cameraStream) {
        this.cameraStream.getTracks().forEach(track => track.stop());
        this.cameraStream = null;
      }
      this.files = null;
    },
    async updateCapturedImage(){
        this.loading = true;
        this.loadingText = "Updating profile picture...";
        let params = {
          files: this.files,
          path: "profile-picture",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadBase64File",
          params
        );
        if (this.getBase64FileUpload && this.getAuthenticatedUser?._id) {
            await this.$store.dispatch("contacts/updateContactData", {
              contact: {
                avatar: this.getBase64FileUpload,
                contact_id: this.getAuthenticatedUser._id
              }
            });
            let user = this.getAuthenticatedUser;
            this.$set(user, "avatar", this.getBase64FileUpload);
            await this.$store.commit("auth/setAuthenticatedUser", null, {
              root: true,
            });
            await this.$store.commit("auth/setAuthenticatedUser", user, {
              root: true,
            });
            this.$notify({
              title: "Success",
              message: "Profile picture updated successfully",
              type: "success"
            });
            this.profilePicture = this.getBase64FileUpload;
            this.outerVisible = false;
            this.upLoadEffect = false;
        } else {
            this.$notify({
              title: "Error",
              message: "Error while uploading",
              type: "error"
            });
            this.profilePicture = this.getFileUploadData;
            this.outerVisible = false;
            this.upLoadEffect = false;
        }
        this.loading = false;
        this.handleDialogClose();
    },
    initializeCamera() {
      if (this.cameraStream) return;

      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.$refs.video.srcObject = stream;
          this.cameraStream = stream;
        })
        .catch(error => {
          console.error('Error accessing camera:', error);
        });
    },
    takePicture() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      // Set canvas dimensions to match the video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert canvas content to base64 data URL
      const dataUrl = canvas.toDataURL('image/jpeg');

      // Set the captured image
      this.capturedImage = dataUrl;
      this.files = {
        type: 'image/jpeg',
        data: dataUrl,
        name: 'abcuruiriie.jpeg',
        mimetype: 'image/jpeg',
      };
    },
    reCapturePic() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.capturedImage = null;
    },
    gotoEditMode() {
      this.refresh = true;
      this.loading = true;
      this.isEdit = true;
      setTimeout(() => {
        this.refresh = false;
        this.loading = false;
      }, 500);
    },
    getTabLabel(tab) {
      if (tab && tab.templateInfo && tab.templateInfo.name) {
        return tab.templateInfo.name;
      }
      return "";
    },
    toggleBgColor() {
      this.isKanban = !this.isKanban;
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      } else {
        return data?.validations?.required;
      }
    },
    checkFieldFillStatus(field, data) {
      return (
        this.checkRepeatableIsDisabled(field, data.indexKey) ||
        this.checkRepeatableFields(field, data.indexKey)
      );
    },
    checkRepeatableIsDisabled(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkRepeatableFields(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    async saveTemplateData() {
      if (!this.entityDataId) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Please refresh.",
        });
        return;
      }
      await this.fetchRepeatableData();
      if (this.checkRequiredFields().showError) {
        if (this.checkRequiredFields().emailFormatError) {
          this.$notify.error({
            title: "Error",
            message: "There is an error in the email format. Please check",
          });
        } else if (this.checkRequiredFields().checkboxMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please select options that are greater than or equal to '${this.minValue}', to meet the minimum selection requirement.`,
          });
        } else if (this.checkRequiredFields().customErrorMessage) {
          this.$notify.error({
            title: "Error",
            message: this.checkRequiredFields().customErrorMessage,
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all required fields",
          });
        }
        return;
      } else if (
        this.activeTab.is_repeatable &&
        this.entitiesData.length < this.min_value
      ) {
        let name =
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
            ? this.entityRepeatableTemplateData.templateInfo.name
            : "template";
        this.$notify.error({
          title: "Error",
          message:
            "Please add " +
            name +
            " data " +
            (this.entitiesData.length > 0
              ? (this.min_value - this.entitiesData.length || 1) + " more times"
              : ""),
        });
      } else {
        this.updateTemplateData();
      }
    },
    async updateTemplateData() {
      try {
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          const promises = await this.entitiesData.map(async (e) => {
            //Update parent entity data by entity variable - mixin method
            await this.updateParentDataByEntityVariable(
              this.getCurrentTemplateFields,
              e
            );
            for (let [key, value] of Object.entries(e)) {
              if (
                value &&
                typeof value == "object" &&
                value.type == "DOCUMENT" &&
                value.new == true &&
                value.file
              ) {
                this.loadingText = "Uploading files...";
                let path = await this.updatedAndUploadDocument(value.file);
                this.$set(e[key], "path", path);
                this.$set(e[key], "new", false);
                // this.form[key].path = path;
                // this.form[key].new = false;
              }
            }
            if (e.isNewData) {
              delete e.isNewData;
            }

            return e;
          });
          form[key] = await Promise.all(promises);
        } else {
          let incrementFields = this.getCurrentTemplateFields.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );
 
          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
                this.form[`${field.key}_info`] = {
                  number: field.auto_increment_starting_number,
                  prefix: field.auto_increment_prefix,
                };
            });
          }
          // form = this.form;
          //Update parent entity data by entity variable - mixin method
          await this.updateParentDataByEntityVariable(
            this.getCurrentTemplateFields,
            this.form
          );
          form = await this.prepareFormData();
          if (
            this.form &&
            this.form.first_name &&
            this.form.last_name &&
            this.form.first_name.length &&
            this.form.last_name.length &&
            !this.form.name
          ) {
            this.form.name = this.form.first_name + " " + this.form.last_name;
          }
        }
        let params = {
          template_data: form,
          entity_data_id: this.entityDataId,
          template_id: this.activeTab.template_id,
          template_data_id: this.templateDataId,
          entity_id: this.entityId,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        if (this.getTemplatesDataUpdateStatus) {
          this.refresh = true;
          if (this.entityPrimaryData) {
            this.$set(this.entityPrimaryData, this.tabModel, {
              ...form,
            });
          }
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
          setTimeout(() => {
            this.refresh = false;
            this.loading = false;
            this.isEdit = false;
          }, 500);
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at updating data",
          });
        }
      } catch (err) {
        console.log(err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    async updatedAndUploadDocument(file) {
      let params = {
        data1: {
          file_name: file.raw.name,
          file_type: file.raw.type,
          file_size_in_kb: file.raw.size / 1000,
        },
        data2: file.raw,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentAndGetUrl",
        params
      );
      if (this.getUploadAndUpdateDocument) {
        return this.getUploadAndUpdateDocument;
      } else {
        return "";
      }
    },
    async prepareFormData() {
      this.loading = true;
      this.loading = "Preparing template data...";
      for (let [key, value] of Object.entries(this.form)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          console.log("value calue vlaue laue", value, this.form);
          this.loadingText = "Uploading files...";
          let path = await this.uploadDocFile(value.files);
          this.form[key].path = path;
          this.form[key].new = false;
        }
      }
      this.loading = false;
      return this.form;
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    // methods to file upload formbuilder
    async uploadDocFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );
      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          console.log("fileuploaded");
        }
        //  else {
        //   this.$message("Sorry ! Error while file uploading");
        // }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    checkRequiredFields() {
      this.requiredFields = [];
      this.minValue = "";
      let showError = false;
      let invalidError = false;
      let emailFormatError = false;
      let checkboxMinMaxCheck = false;
      let customErrorMessage = "";
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.activeTab.is_repeatable) {
        this.entitiesData.push(this.form);
      }
      this.entitiesData.forEach((data) => {
        this.getCurrentTemplateFields.forEach((field) => {
          if (this.fieldFilledByCurrentUser(field.filled_by)) {
            if (data[field.key] && typeof data[field.key] == "string") {
              data[field.key] = data[field.key].trim();
            }
            if (
              field.input_type == "WEEKDAYS" &&
              this.checkIsRequired(field) &&
              this.checkIsNotFilled(field, data)
            ) {
              if (field.allow_multiple) {
                customErrorMessage =
                  "Please select " +
                  field.min_value +
                  " options for " +
                  field.label +
                  " field";
              }
              showError = true;
              this.requiredFields.push(field.label);
            } else if (
              field.validations &&
              field.validations.required &&
              //!data[field.key] &&
              ((!this.checkIsDisabled(field) &&
                !this.checkhideFields(field) &&
                !this.activeTab?.is_repeatable) ||
                (this.activeTab?.is_repeatable &&
                  !this.checkFieldFillStatus(field, data)))
            ) {
              if (
                field.validations.type &&
                field.validations.type == "ARRAY" &&
                field.key &&
                data[field.key] &&
                !data[field.key].length
              ) {
                showError = true;
                this.requiredFields.push(field.label);
              } else if (field.key && !data[field.key]) {
                showError = true;
                this.requiredFields.push(field.label);
              }
            }

            if (
              this.templateData &&
              this.templateData.type == "STANDARD" &&
              this.templateData.slug == "contactdetails"
            ) {
              if (field.key == "first_name") {
                if (data[field.key] && !data[field.key].trim().length) {
                  emailFormatError = false;
                  checkboxMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                }
              } else if (field.key == "last_name") {
                if (data[field.key] && !data[field.key].trim().length) {
                  emailFormatError = false;
                  checkboxMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                }
              } else if (field.key == "email") {
                if (
                  data[field.key] &&
                  data[field.key].toLowerCase() ==
                    this.getAuthenticatedUser.email.toLowerCase()
                ) {
                  this.$message({
                    message: "You cannot add sender email!.",
                    type: "error",
                  });
                  data[field.key] = "";
                  emailFormatError = false;
                  checkboxMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                } else {
                  if (
                    data[field.key] &&
                    (!data[field.key].length || !reEmail.test(data[field.key]))
                  ) {
                    console.log("data[field.key]", data[field.key]);
                    //   this.$message({
                    //   message: `There is an error in the email format. '${data[field.key]}'  Please check.`,
                    //   type: "error",
                    // });
                    emailFormatError = true;
                    checkboxMinMaxCheck = false;
                    showError = true;
                    invalidError = true;
                    this.requiredFields.push(field.label);
                  }
                }
              }
            }
            if (
              field.inputType == "CHECKBOX_GROUP" &&
              field.min_selection &&
              (!data[field.key] ||
                !data[field.key].length ||
                data[field.key].length < field.min_selection)
            ) {
              checkboxMinMaxCheck = true;
              showError = true;
              invalidError = true;
              this.requiredFields.push(field.label);
              this.minValue = field.min_selection;
            }
          }
        });
      });
      return {
        showError,
        invalidError,
        emailFormatError,
        checkboxMinMaxCheck,
        customErrorMessage,
      };
    },
    hasPermission(permission) {
      if (
        this.permissionSet &&
        this.tabModel &&
        this.permissionSet[this.tabModel] &&
        this.permissionSet[this.tabModel].indexOf(permission) != -1
      ) {
        return true;
      }
      return false;
    },
    handleClick(tab) {
      this.isEdit = false;
      this.setTabWithIndex(tab.index);
    },
    async getNeccesaryInfo(id, dataId) {
      this.entityDataId = dataId;
      this.loading = true;
      await Promise.all([
        this.$store.dispatch("entities/fetchEntityById", {
          entity_id: id,
        }),
        this.$store.dispatch(
          "applicationUsersPermission/fetchPermissionsEntityById",
          { id: id }
        ),
        this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
          id: dataId,
        }),
      ]);
      if (this.getEntityDataById) {
        this.alltabs = [];
        if (
          this.getEntityPrimaryData &&
          this.getEntityPrimaryData.templates_data
        ) {
          this.getEntityPrimaryData.templates_data.forEach((temp) => {
            if (temp?.template_data_id?.template_data) {
              this.entityPrimaryData[temp.template_id] =
                temp.template_data_id.template_data;
            } else {
              this.entityPrimaryData[temp.template_id] = {};
            }
          });
        }
        if (this.getEntityPermission?._id) {
          if (this.getEntityPermission?.profile_permissions?.templates) {
            (this.getEntityDataById.templates || []).forEach((temp) => {
              if (
                temp.template_id &&
                this.getEntityPermission?.profile_permissions?.templates[
                  temp.template_id
                ] &&
                this.getEntityPermission?.profile_permissions?.templates[
                  temp.template_id
                ].indexOf("VIEW") != -1
              ) {
                this.alltabs.push(temp);
                this.permissionSet[temp.template_id] =
                  this.getEntityPermission?.profile_permissions?.templates[
                    temp.template_id
                  ];
              }
            });
          }
        } else {
          (this.getEntityDataById.templates || []).forEach((temp) => {
            if (temp.template_id) {
              this.alltabs.push(temp);
              this.permissionSet[temp.template_id] = ["VIEW", "EDIT"];
            }
          });
        }
        this.setTabWithIndex(0);
      }
      this.loading = false;
      // console.log("repeatble fields........................",this.alltabs);
    },
    async setTabWithIndex(index) {
      if (
        this.alltabs &&
        this.alltabs[index] &&
        this.alltabs[index]?.templateInfo
      ) {
        this.refresh = true;
        this.activeTab = this.alltabs[index];
        this.tabModel = this.alltabs[index].templateInfo?._id;
        this.form = {};
        this.entitiesData = [];
        if (this.entityPrimaryData[this.tabModel]) {
          this.form = JSON.parse(
            JSON.stringify(this.entityPrimaryData[this.tabModel])
          );
        }
        await this.checkRepeatableTemplate();
        this.refresh = false;
      }
    },
    async checkRepeatableTemplate() {
      if (this.activeTab?.is_repeatable) {
        this.entityRepeatableTemplateData = {
          senderAlias: this.activeTab?.sender_title,
          default_display: "TABLE",
          receiverAlias: this.activeTab?.receiver_title,
          max_records: this.activeTab?.max_records,
          templateInfo: this.activeTab?.templateInfo,
          max_linked_field: this.activeTab?.max_linked_field,
          min_linked_field: this.activeTab?.min_linked_field,
          min_records: this.activeTab?.min_records,
        };
        this.hasRepeatableTemplate = true;
      } else if (this.getEntityDataById && this.getEntityDataById.templates) {
        this.hasRepeatableTemplate = false;
      }
      console.log("0", this.hasRepeatableTemplate, this.activeTab);
      if (this.hasRepeatableTemplate) {
        console.log("-1");
        this.dependentFieldsInfo();
      }
    },
    dependentFieldsInfo() {
      console.log("1");
      let templateIds = [];
      if (
        this.entityRepeatableTemplateData.min_linked_field &&
        this.entityRepeatableTemplateData.min_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[2],
          is_max: false,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_linked_field &&
        this.entityRepeatableTemplateData.max_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[2],
          is_max: true,
        });
      }
      if (this.entityRepeatableTemplateData.max_records) {
        this.max_value = this.entityRepeatableTemplateData.max_records;
      }
      if (this.entityRepeatableTemplateData.min_records) {
        console.log("2");
        this.min_value = this.entityRepeatableTemplateData.min_records;
      }
      if (this.max_value < this.min_value) {
        this.max_value = this.min_value;
      }
      this.fetchDependentData(templateIds);
    },
    async fetchDependentData(templateIds) {
      if (templateIds.length) {
        await this.$store.dispatch("templatesData/fetchRequiredTemplateData", {
          entity_data_ids: [this.entityDataId],
          template_ids: templateIds.flatMap((e) => e.template_id),
        });
        if (
          this.getRequiredTemplatesData &&
          this.getRequiredTemplatesData.length
        ) {
          templateIds.forEach((e) => {
            let seletedTemplateData = this.getRequiredTemplatesData.find(
              (te) => te.template_id == e.template_id
            );
            if (
              seletedTemplateData?.template_data &&
              seletedTemplateData?.template_data[e.field]
            ) {
              if (e.is_max) {
                this.max_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              } else {
                console.log("3");
                this.min_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              }
            }
            if (this.max_value < this.min_value) {
              this.max_value = this.min_value;
            }
          });
        }
      }
    },
    handleScroll(event) {
      this.scrollTop = event.target.scrollTop;

      console.log("scrollTop:", this.scrollTop);
    },
    async onUpdate() {
      this.upLoadEffect = true;
      if (
        this.files &&
        (this.files.type == "image/jpeg" ||
          this.files.type == "image/png" ||
          this.files.type == "image/jpg")
      ) {
        var formData = new FormData();
        formData.append(
          "files",
          this.files !== undefined ? this.files : this.files
        );

        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
        if (this.getFileUploadData) {
          let params = {
            file: this.getFileUploadData,
          };
          await this.$store.dispatch(
            "auth/fetchupdateProfilePicStatus",
            params
          );
          if (this.getUpdateProfilePicStatus) {
            this.$notify({
              title: "Success",
              message: "Profile Picture Updated Successfully",
              type: "success",
            });
            let user = this.getAuthenticatedUser;
            this.$set(user, "avatar", this.getFileUploadData);
            await this.$store.commit("auth/setAuthenticatedUser", null, {
              root: true,
            });
            await this.$store.commit("auth/setAuthenticatedUser", user, {
              root: true,
            });
            this.outerVisible = false;
            this.upLoadEffect = false;
            this.buttondisale = true;
          } else {
            this.$notify({
              title: "Error",
              message: "Error while updating profile",
              type: "error",
            });
            this.upLoadEffect = false;
            this.buttondisale = true;
          }
        } else {
          this.loading = false;
          this.$alert("Error while uploading file");
        }
      } else {
        this.loading = false;
        this.$alert("Please upload a image file");
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    uploadFile(file) {
      this.imgData = file;
      this.profileData = file;
      if (file && typeof file == "string") {
        this.files = this.dataURLtoFile(file, "image1");
      } else {
        this.files = file;
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    async deleteProfile() {
      this.$confirm("Are you sure you want to delete the profile picture?")
        .then(async () => {
          // let isRemovingProfilePicture = false;
          let params = {
            file: "",
            isRemovingProfilePicture: true,
          };
          await this.$store.dispatch(
            "auth/fetchupdateProfilePicStatus",
            params
          );
          if (this.getUpdateProfilePicStatus) {
            this.resetImgData();
            this.$notify({
              title: "Success",
              message: "Profile picture deleted successfully.",
              type: "success",
            });
            window.location.reload();
          } else {
            this.$notify({
              title: "Error",
              message: "Error in deleting profile",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    addRepeatableData() {
      bus.$emit("addApRepeatableData", this.activeTab?.template_id);
    },
    async fetchRepeatableData() {
      await bus.$emit("getApRepeatableData", this.activeTab?.template_id);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-profile {
  width: 100% !important;
  align-items: center !important;
}
.container {
  background-color: white;
  background-size: cover;
  height: 150vh;
  margin-left: -3rem;
  margin-top: -4rem;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.new-content {
  border-radius: 2px;
  min-height: 36px;
  margin: 2em;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.image {
  vertical-align: middle;
  max-width: 9rem !important;
  max-height: 9rem !important;
  border-radius: 50%;
}

.image:hover {
  vertical-align: middle;
  opacity: 0.3;
  border-radius: 50%;
}
.grid-content {
  border-radius: 4px;
  background-color: white;
}
.grid-content2 {
  border-radius: 4px;
  min-height: 40vh;
  background-color: white;
  margin: 0;
  color: #9299b8;
  font-size: 10px;
}
.grid {
  border-radius: 4px;
  background-color: white;
}
.text {
  font-size: 14px;
  color: black;
}
::-webkit-scrollbar {
  width: 0.425rem;
}
::-webkit-scrollbar-track {
  background: #f1f0f0;
}
::-webkit-scrollbar-thumb {
  background: #888;
  background-color: rgb(176, 174, 174);
}
::-webkit-scrollbar-thumb:hover {
  background: #8a8888;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.box-card {
  width: 100%;
  border-radius: 0;
}
.tab {
  color: #9299b8;
}
.applicationel-tabs__active-bar {
  background: #409eff !important;
  color: #9299b8;
  font-size: 4px;
}
.actions-sty {
  // float: right;
  //width: 18px;
}
.img-sty {
  width: 18px;
  height: 18px;
}
.applicationUserProfileTabs {
  color: #9299b8;
  font-size: 5px;
  text-align: left !important;
}

.radio-group-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 15px;
}
.retake-btn{
  margin-top: 10px;
}
.preview-text{
  background-color: #ecf5ff;
  font-weight: 450;
  width: 400px;
  height: 25px;
  font-size: 18;
}
</style>
